export enum FlowstepPlanId {
  FlowstepLaunch = 'flowstep-launch',
  FlowstepFree = 'flowstep-free',
  FlowstepTeamMonthly = 'flowstep-team-monthly',
  FlowstepTeamAnnually = 'flowstep-team-annually',
  FlowstepCompanyMonthly = 'flowstep-company-monthly',
  FlowstepCompanyAnnually = 'flowstep-company-annually',
  FlowstepMediumMonthly = 'flowstep-medium-monthly',
  FlowstepMediumAnnually = 'flowstep-medium-annually',
  FlowstepLargeMonthly = 'flowstep-large-monthly',
  FlowstepLargeAnnually = 'flowstep-large-annually',
  FlowstepEnterpriseMonthly = 'flowstep-enterprise-monthly',
  FlowstepEnterpriseAnnually = 'flowstep-enterprise-annually',
}

export interface FlowstepPlan {
  type: 'monthly' | 'annually'
  price: number
  planId: FlowstepPlanId
  paddlePlanId: number | string
  addonId?: string
  pricingPlanDescription: string
  pricingPlanName: string
  numberOfOrganisations: number
  includedUsers: number
  maximumNumberOfAdditionalUsers: number
  numberOfProjects: number | null
  numberOfUserFlows: number | null
  numberOfApis: number | null
  numberOfDataModels: number | null
  pricePerExtraUser: number
}

export const flowstepLargerPlansPricingTable = ({
  flowStepPlans,
}: {
  flowStepPlans?: FlowstepPlan[]
}) => {
  return flowStepPlans?.filter((flowstepPlan) => {
    if (flowstepPlan.planId === FlowstepPlanId.FlowstepMediumMonthly) {
      return true
    }
    if (flowstepPlan.planId === FlowstepPlanId.FlowstepLargeMonthly) {
      return true
    }
    if (flowstepPlan.planId === FlowstepPlanId.FlowstepEnterpriseMonthly) {
      return true
    }
    return false
  })
}

export const flowstepStandardPlansPricingTable = ({
  flowStepPlans,
}: {
  flowStepPlans?: FlowstepPlan[]
}) => {
  return flowStepPlans?.filter((flowstepPlan) => {
    if (flowstepPlan.planId === FlowstepPlanId.FlowstepFree) {
      return true
    }
    if (flowstepPlan.planId === FlowstepPlanId.FlowstepTeamMonthly) {
      return true
    }
    if (flowstepPlan.planId === FlowstepPlanId.FlowstepCompanyMonthly) {
      return true
    }
    return false
  })
}
