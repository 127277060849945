import Country from 'models/country'
import { UseQueryOptions, useQuery } from 'react-query'
import GeographyService from 'services/geography'

const useCountries = (queryConfig?: UseQueryOptions<Country[], any>) => {
  return useQuery('COUNTRIES', () => GeographyService.getCountries(), {
    cacheTime: 60 * 1000 * 60 * 24,
    staleTime: 60 * 1000 * 60 * 24,
    ...queryConfig,
  })
}

export default useCountries
