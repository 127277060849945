import React from 'react'
import { components } from 'react-select'
import ReactSelect, { Props as SelectProps } from 'react-select'

const { Control, IndicatorsContainer } = components

export interface SelectOptionProps {
  value: any
  label?: React.ReactNode
}

export interface CoreSelectProps extends Omit<SelectProps, 'value'> {
  name?: string
  disabled?: boolean
  value?: string | number | boolean | SelectOptionProps
  emitValueOnChange?: boolean
  convertNULLString?: boolean
  convertBooleanString?: boolean
  isLoading?: boolean
  hideDropdownIndicators?: boolean
}

const Select: React.FC<CoreSelectProps> = React.forwardRef(
  (
    {
      name,
      options,
      value,
      onChange,
      emitValueOnChange,
      disabled,
      placeholder,
      convertNULLString,
      convertBooleanString,
      isLoading,
      hideDropdownIndicators,
      ...rest
    },
    ref,
  ) => {
    const handleChange = React.useCallback(
      (selected: any) => {
        if (emitValueOnChange) {
          if (Array.isArray(selected)) {
            return onChange?.(selected?.map((option) => option?.value) as any, {
              action: 'select-option',
            })
          }
          return onChange?.(selected?.value, {
            action: 'select-option',
          })
        }
        onChange?.(selected, {
          action: 'select-option',
        })
      },
      [emitValueOnChange, onChange],
    )

    const normalizedValue = React.useMemo(() => {
      if (
        (typeof value !== 'object' && typeof value !== 'function') ||
        value === null
      ) {
        let val: any

        let valueToFind: any = value

        if (convertNULLString) {
          valueToFind = value === 'null' ? null : value
        }
        if (convertBooleanString) {
          valueToFind = value === 'true' || value === true ? true : false
        }

        options?.find((option) => {
          if (option.options) {
            val = option.options.find(
              (opt) => opt.value?.toString() === valueToFind?.toString(),
            )
            if (val) {
              return true
            }
            return false
          }
          val =
            option.value?.toString() === valueToFind?.toString()
              ? option
              : undefined
          return !!val
        })
        return val
      }
      return value
    }, [options, value, convertNULLString, convertBooleanString])

    const customComponents = React.useMemo(
      () => ({
        Control: (props) => (
          <Control
            {...props}
            css={
              rest.className?.includes('border-transparent') && {
                border: 'border-flowStep40 hover:border-flowStep60',
                boxShadow: 'none !important',
              }
            }
          />
        ),
        IndicatorsContainer: (props) => {
          if (hideDropdownIndicators) {
            return null
          }

          return (
            <IndicatorsContainer
              {...props}
              css={
                rest.className?.includes(
                  'border-transparent border-flowStep40 hover:border-flowStep60',
                ) && {
                  display: 'none !important',
                }
              }
            />
          )
        },
      }),
      [hideDropdownIndicators, rest.className],
    )

    return (
      <ReactSelect
        ref={ref as any}
        {...rest}
        placeholder={placeholder}
        name={name}
        options={options}
        value={
          normalizedValue?.value !== undefined && {
            value: normalizedValue?.value,
            label: normalizedValue?.label,
          }
        }
        onChange={handleChange}
        isDisabled={disabled || rest.isDisabled}
        components={{ ...rest.components, ...customComponents }}
        classNamePrefix="react-select"
        isLoading={isLoading}
      />
    )
  },
)

Select.displayName = 'Select'

export default Select
