import Button from 'components/Button'
import Select from 'components/Select'
import { FlowstepPlanId } from 'consts/flowstep-plans'
import useCountries from 'hooks/useCountries'
import groupBy from 'lodash/groupBy'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

export interface LaunchOfferProps {
  showPricingLink?: boolean // if show pricing link disable scroll to element on 'more'
}

const usersOptions = [
  { value: 5, label: '5 ($50/m)', cost: 50, savings: 150 },
  { value: 10, label: '10 ($100/m)', cost: 100, savings: 100 },
  { value: 15, label: '15 ($150/m)', cost: 150, savings: 200 },
  { value: 20, label: '20 ($200/m)', cost: 200, savings: 300 },
  { value: 25, label: '25 ($250/m)', cost: 250, savings: 400 },
  { value: 'more', label: 'More than 25 Users' },
]

const LaunchOffer: React.FC<LaunchOfferProps> = ({ showPricingLink }) => {
  const { data: countries } = useCountries()

  const [totalNumberOfUsers, setTotalNumberOfUsers] = React.useState<
    string | number
  >(5)
  const selectedOption = usersOptions?.find(
    (userOption) => userOption.value === totalNumberOfUsers,
  )

  const router = useRouter()
  const passedUsersOption = router?.query?.usersOption

  React.useEffect(() => {
    if (passedUsersOption === 'more') {
      setTotalNumberOfUsers(passedUsersOption)
      scrollToElement('standard-pricing')
    }
  }, [passedUsersOption])

  const countryGroups = groupBy(countries, 'region.name')

  const [selectedCountry, setSelectedCountry] = React.useState('')
  const [displayCountryWarning, setDisplayCountryWarning] =
    React.useState(false)

  const handleBuyTeamLaunch = () => {
    if (typeof window === 'undefined') {
      return
    }

    if (!selectedCountry) {
      setDisplayCountryWarning(true)
      return
    }

    if (isNaN(Number(totalNumberOfUsers))) {
      return
    }

    router.push({
      pathname: `/account/create`,
      query: {
        planId: FlowstepPlanId.FlowstepLaunch,
        country: selectedCountry,
        totalNumberOfUsers,
      },
    })
  }

  const scrollToElement = (elementId: string) => {
    const findPos = (obj: any) => {
      let curtop = -20
      if (obj?.offsetParent) {
        do {
          curtop += obj?.offsetTop
        } while ((obj = obj?.offsetParent))
        return curtop
      }
    }

    window.scroll(0, findPos(document?.getElementById?.(elementId)) ?? 0)
  }

  return (
    <>
      {/* Launch Offer */}

      <main className="py-8 mt-0 mb-8 bg-flowStep150_BG xl:rounded-lg">
        <div className="mx-auto max-w-7xl">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="px-4 md:max-w-2xl md:mx-auto lg:col-span-6">
              <div>
                <h2 className="mt-0 mb-2 font-extrabold tracking-tight md:mt-6 sm:leading-none">
                  "Team" Plan Launch Offer
                </h2>
                <h5 className="pt-0 mt-2 text-white md:block">
                  $10 <span className="text-base">(USD) /user /mo </span>
                </h5>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="country"
                    className="block text-sm font-bold text-flowStep"
                  >
                    Select Your Country
                  </label>
                  <div className="mt-1">
                    <Select
                      name="country"
                      autoComplete="country"
                      className="block w-full rounded-md shadow-sm placeholder-flowStep60 border-flowStep20 focus:ring-flowStep40 focus:border-flowStep40 sm:text-sm"
                      options={Object.entries(countryGroups).map(
                        ([region, countriesOnRegion]) => ({
                          label: region,
                          options: countriesOnRegion.map((country) => ({
                            value: country.code,
                            label: country.name,
                          })),
                        }),
                      )}
                      value={selectedCountry}
                      onChange={(val) => setSelectedCountry(val)}
                      emitValueOnChange
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="users"
                    className="block text-sm font-bold text-flowStep"
                  >
                    How many users?
                  </label>
                  <div className="mt-1">
                    <Select
                      id="users"
                      name="users"
                      className="block w-full rounded-md shadow-sm placeholder-flowStep60 border-flowStep20 focus:ring-flowStep40 focus:border-flowStep40 sm:text-sm"
                      value={totalNumberOfUsers}
                      onChange={(value) => {
                        if (value === 'more' && !showPricingLink) {
                          scrollToElement('standard-pricing')
                        }

                        setTotalNumberOfUsers(value)
                      }}
                      options={usersOptions}
                      emitValueOnChange
                    />
                  </div>
                </div>

                <p className="mt-3 text-xs text-flowStep sm:mt-5 lg:text-sm">
                  <strong>Please note:</strong> Additional users can only be
                  added at the promotional price whilst the promotion is still
                  available in your region. If you think you'll need more users
                  in the future, consider buying the maximum 25 users now, so
                  you don't miss out later.
                </p>
              </div>
            </div>

            <div className="mt-10 lg:mt-0 lg:col-span-6">
              <div
                className={`bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden h-full flex justify-center items-center ${
                  totalNumberOfUsers === 'more' ? '' : 'hidden'
                }`}
              >
                {!showPricingLink && (
                  <button onClick={() => scrollToElement('standard-pricing')}>
                    <h3>For more than 25 users see standard pricing below</h3>
                  </button>
                )}
                {showPricingLink && (
                  <Link
                    href={{
                      pathname: '/pricing',
                      query: { usersOption: 'more' },
                    }}
                  >
                    <a>
                      <Button color="primary">Go to Pricing</Button>
                    </a>
                  </Link>
                )}
              </div>
              <div
                className={`bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden ${
                  totalNumberOfUsers === 'more' ? 'hidden' : ''
                }`}
              >
                <div className="flex flex-col px-4 py-6 bg-white sm:px-6">
                  <h4 className="items-baseline my-0 font-extrabold leading-tight">
                    <span>{totalNumberOfUsers} users </span>
                    <span>@ ${selectedOption?.cost}</span>
                    <span className="font-medium text-flowStep60"> (USD) </span>
                    <span className="text-lg font-medium">/m</span>
                  </h4>
                  <span className=" text-flowStep60">*excluding tax</span>

                  <h6 className="px-4 py-2 mt-2 mb-0 font-semibold tracking-wide text-center text-white rounded-lg bg-flowAction">
                    Saving ${selectedOption?.savings}/mo
                  </h6>
                </div>
                <div className="flex flex-col justify-between flex-1 px-4 pt-0 pb-8 space-y-6">
                  <h5 className="my-0">Team plan inclusions:</h5>
                  <ul className="space-y-0 sm:flex-wrap sm:flex">
                    <li className="flex items-start sm:w-1/2">
                      <div className="flex-shrink-0">
                        {/* Heroicon name: check */}
                        <svg
                          className="w-6 h-6 text-flowOutcome"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <p className="mb-2 ml-3 text-base text-flowStep60">
                        1 Organization
                      </p>
                    </li>
                    <li className="flex items-start sm:w-1/2">
                      <div className="flex-shrink-0">
                        {/* Heroicon name: check */}
                        <svg
                          className="w-6 h-6 text-flowOutcome"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <p className="mb-2 ml-3 text-base text-flowStep60">
                        5 - 25 users
                      </p>
                    </li>
                    <li className="flex items-start sm:w-1/2">
                      <div className="flex-shrink-0">
                        {/* Heroicon name: check */}
                        <svg
                          className="w-6 h-6 text-flowOutcome"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <p className="mb-2 ml-3 text-base text-flowStep60">
                        5 Projects
                      </p>
                    </li>
                    <li className="flex items-start sm:w-1/2">
                      <div className="flex-shrink-0">
                        {/* Heroicon name: check */}
                        <svg
                          className="w-6 h-6 text-flowOutcome"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <p className="mb-2 ml-3 text-base text-flowStep60">
                        50 User Flows
                      </p>
                    </li>
                    <li className="flex items-start sm:w-1/2">
                      <div className="flex-shrink-0">
                        {/* Heroicon name: check */}
                        <svg
                          className="w-6 h-6 text-flowOutcome"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <p className="mb-2 ml-3 text-base text-flowStep60">
                        25 APIs
                      </p>
                    </li>
                    <li className="flex items-start sm:w-1/2">
                      <div className="flex-shrink-0">
                        {/* Heroicon name: check */}
                        <svg
                          className="w-6 h-6 text-flowOutcome"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <p className="mb-2 ml-3 text-base text-flowStep60">
                        50 Data Models
                      </p>
                    </li>
                  </ul>
                  <div className="rounded-md shadow">
                    <button
                      onClick={handleBuyTeamLaunch}
                      className="flex items-center justify-center w-full px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-flowStep hover:bg-flowStep150 hover:text-white"
                      aria-describedby="tier-standard"
                    >
                      Buy "Team Launch" now!
                    </button>
                  </div>
                  {displayCountryWarning && !selectedCountry && (
                    <p className="text-center text-flowAction">
                      Please select a country
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default LaunchOffer
