import { APIUrl } from 'consts/endpoints'
import Country from 'models/country'
import HttpService from './http'

const GeographyService = {
  getCountries: async () => {
    const { data } = await HttpService.instance.get<Country[]>(
      `${APIUrl}/v1/countries`,
    )
    return data
  },
  getRegions: async () => {
    const { data } = await HttpService.instance.get(`${APIUrl}/v1/regions`)
    return data
  },
}

export default GeographyService
